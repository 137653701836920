<template>
    <div class="col-12">
        <template v-if="notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
        </template>
        <div v-else>
            <form @submit.prevent="submit" novalidate class="mt-5">
                
                <bank-select  v-if="processorName == 'LUQAPAY'" requestPath="luqapay/availablePayoutBanks" v-on:selectedBank="selectedBank"></bank-select>

                <div class="form-group floating-input mt-3 input-group">
                    <input type="number" class="form-control" id="withdrawAmount" v-model.trim="$v.amount.$model" :placeholder="$t('amount')" required>
                    <label for="withdrawAmount">{{$t('amount')}}</label>
                    <div class="input-group-append">
                        <span class="input-group-text">{{playerCurrency}}</span>
                    </div>
                </div>
                <div class="validation-error">
                    <label v-if="!$v.amount.required && $v.amount.$dirty">{{$t('please-enter-your', { field: $t('amount') })}}</label>
                    <label v-else-if="!$v.amount.numeric && $v.amount.$dirty">{{$t('not-a-number')}}</label>
                    <label v-else-if="!$v.amount.minValue && $v.amount.$dirty">{{$t('min-amount', { value: minWithdrawal}) }}</label>
                    <label v-else-if="!$v.amount.maxValue && $v.amount.$dirty">{{$t('max-amount', { value: maxWithdrawal}) }}</label>
                </div>

              <div class="form-group floating-input mt-3"  v-if="walletType == 'BANKTRANSFER'">
                <input type="text" class="form-control" id="iban" v-model.trim="$v.iban.$model" :placeholder="$t('iban')" required>
                <label for="iban">{{$t('iban')}}</label>
              </div>


                <div class="form-group floating-input mt-3"  v-if="walletType == 'PAYFIX'">
                    <input type="text" class="form-control" id="customerName" v-model.trim="$v.customerName.$model" :placeholder="$t('customer-name')" required>
                    <label for="customerName">{{$t('customer-name')}}</label>
                </div>
                


                <div class="form-group floating-input mt-3"  v-if="walletType == 'PAYFIX'">
                    <input type="text" class="form-control" id="customerIdentificationNumber-payfix" v-model.trim="$v.customerIdentificationNumber.$model" :placeholder="$t('customer-identification-number-payfix')" required>
                    <label for="customerIdentificationNumber-payfix">{{$t('customer-identification-number-payfix')}}</label>
                </div>

              <div class="form-group floating-input mt-3"  v-if="walletType == 'MEFETE'">
                <input type="text" class="form-control" id="customerIdentificationNumber-mefete" v-model.trim="$v.customerIdentificationNumber.$model" :placeholder="$t('customer-identification-number-mefete')" required>
                <label for="customerIdentificationNumber-mefete">{{$t('customer-identification-number-mefete')}}</label>
              </div>

              <div class="form-group floating-input mt-3" v-if="walletType == 'MEFETE' ">
                <input type="text" class="form-control" id="accountNumber-mefete" v-model.trim="$v.address.$model" :placeholder="$t('account-number-mefete')" required>
                <label for="accountNumber-mefete">{{$t('account-number-mefete')}}</label>
              </div>

              <div class="form-group floating-input mt-3" v-if="walletType == 'EPARA' || walletType == 'PAPARA' || walletType == 'ANIDA'">
                <input type="text" class="form-control" id="accountNumber-epara" v-model.trim="$v.address.$model" :placeholder="$t('account-number-epara')" required>
                <label for="accountNumber-epara">{{$t('account-number-epara')}}</label>
              </div>

<!--              <div class="form-group floating-input mt-3"  v-if="walletType == 'BANKTRANSFER' && processorName == 'ASTURKEY'">-->
<!--                <input type="text" class="form-control" id="swiftCode" v-model.trim="$v.swiftCode.$model" :placeholder="$t('swift-code')" required>-->
<!--                <label for="swiftCode">{{$t('swift-code')}}</label>-->
<!--              </div>-->

              <div class="form-group floating-input mt-3"  v-if="walletType == 'BANKTRANSFER' && processorName == 'ASTURKEY'">
                <input type="text" class="form-control" id="bankName" v-model.trim="$v.bankName.$model" :placeholder="$t('bank-name')" required>
                <label for="bankName">{{$t('bank-name')}}</label>
              </div>

                <button class="btn btn-success col-12 mt-3" :class="{'disabled':submitStatus === 'PENDING'}">
                    <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                    <div v-else class="d-flex align-items-center">
                        <strong>{{$t('loading')}}...</strong>
                        <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                </button>
                <div>
                    <label v-if="submitStatus === 'ERROR'" class="validation-error">{{withdrawError}} {{$t('request-unsuccessful')}}</label>
                    <label v-if="submitStatus === 'OK'" class="validation-success">{{$t('request-successful')}}</label>
                </div>
                <br/>
                <p class="text-center">{{$t('payout-note')}}</p>
            </form>
        </div>
    </div>
</template>

<script>
    import {withdraw} from "../../../services/backend.service";
    import {validationMixin} from 'vuelidate';
    import {required, numeric, minValue, maxValue} from 'vuelidate/lib/validators';

    export default {
        name: 'Withdraw',
        data() {
            return {
                notEnoughData: null,
                withdrawError: null,
                customerId: null,
                maxWithdrawal: null,
                minWithdrawal: null,
                playerCurrency: null,
                processorName: null,
                processorType: null,
                walletType: null,
                ipAddress: null,
                token: null,
                amount: null,
                address: null,
                customerName: null,
                customerIdentificationNumber: null,
                iban: null,
                bankName:null,
                submitStatus: null,
                selectedBankCode: null,
            }
        },
        components: {
            Error: () => import('../../others/Error'),
            BankSelect: () => import('../../others/BankSelect'),
        },
        mixins: [validationMixin],
        validations() {

            let validators = {
                amount: {
                    required,
                    numeric,
                    minValue: minValue(this.minWithdrawal),
                    maxValue: maxValue(this.maxWithdrawal)
                },
                address: {

                },
                customerName: {
                },
                customerIdentificationNumber: {
                },
                iban:{
                },
                bankName:{
                },
                swiftCode:{

                }
            }
            
            if(this.showAccountNumber()){
                validators.address = {required}
            }

            return validators;
        },
        created() {
            this.processorName = this.$router.history.current.query.processorName;
            this.customerId = this.$router.history.current.query.customerId;
            this.processorType = this.$router.history.current.query.processorType;
            this.walletType = this.$router.history.current.query.walletType;
            this.ipAddress = this.$router.history.current.query.ipAddress;
            this.token = this.$router.history.current.query.token;
        },

        mounted() {
            this.notEnoughData = !this.customerId || !this.processorName || !this.ipAddress;

            this.playerCurrency = this.$router.history.current.query.playerCurrency;
            this.minWithdrawal = this.getMinimumWithdrawal();

            this.maxWithdrawal = this.getMaximumWithdrawal();
            // if(this.$router.history.current.query.maxWithdrawal){
            //     maxWithdrawal = parseInt(this.$router.history.current.query.maxWithdrawal);
            //     this.maxWithdrawal = maxWithdrawal > 10000 ? 10000 : maxWithdrawal;
            // } else {
            //     // set default
            //     this.maxWithdrawal = 10000;
            // }
        },
        methods: {
            showAccountNumber(){
                if(this.processorName == 'JETON'){
                    return false;
                }
                else if (this.walletType == 'PAYFIX'){
                    return false;
                }

                else if (this.walletType == 'BANKTRANSFER'){
                    return false;
                }



                return true;
            },
            getMinimumWithdrawal(){
                if(this.playerCurrency === 'TRY'){
                    return 200;
                } 
                return 10;
            },
            getMaximumWithdrawal(){
                if(this.playerCurrency === 'TRY'){
                    return 25000;
                }
                return 2000;
            },
            selectedBank(bank){
                this.selectedBankCode = bank.swiftCode;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // this.submitStatus = 'ERROR';
                } else {
                    this.submitStatus = 'PENDING';

                    let data = {
                        customerId: this.customerId,
                        processorName: this.processorName,
                        processorType: this.processorType,
                        walletType: this.walletType,
                        ipAddress: this.ipAddress,
                        currency: this.playerCurrency,
                        // Backend is working with cents so we need to pass cents hence * 100
                        amount: this.amount * 100,
                        payoutToken: this.token,
                    };

                    if(this.selectedBankCode){
                        data.swiftCode = this.selectedBankCode;
                    }
                    //IF JETON
                    if(this.address){
                        data.accountNumber = this.address;
                    }

                    if(this.customerName){
                        data.customerName = this.customerName;
                    }

                    if(this.customerIdentificationNumber){
                        data.customerIdentificationNumber = this.customerIdentificationNumber;
                    }

                  if(this.iban){
                    data.IBAN = this.iban;
                  }

                  if(this.swiftCode){
                    data.swiftCode = this.swiftCode;
                  }

                  if (this.bankName){
                    data.bankName = this.bankName
                  }




                    
                    withdraw(data).then((res) => {
                        if(res.data.success && res.data.data.status !== "ERROR"){
                            this.submitStatus = 'OK';
                        } else {
                            this.withdrawError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                            this.submitStatus = 'ERROR';
                        }
                    }).catch( e => {
                        this.submitStatus = 'ERROR';
                        console.log(`%c Error in Withdraw.vue - method: submit - ${e}`, 'color:red');
                    });
                }
            }
        }
    }
</script>

<style scoped></style>